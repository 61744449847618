import moment from "moment";
import React, {useEffect} from "react";
import StatisticValue from "../StatisticValue";
import {capitalize} from "../firstDayOfPeriod";
import Loading from "../Loading";
import SalesBarChart from "./SalesBarChart";
import {useRangeStatistics} from "./hooks/useRangeStatistics";
import {useTerminalStatistics} from "./hooks/useTerminalStatistics";
import TerminalSalesList from "../TerminalSalesList";
import {ArticleGroupList} from "./ArticleGroupList";
import {useArticleStatistics} from "./hooks/useArticleStatistics";
import {useTranslation} from "react-i18next";

const RangeDashboard = (props) => {
  const {t}  = useTranslation();
  const {profitCenter, profitCenterArea, periodFrom, periodTo, compareFrom, compareTo} = props;
  
  const from = moment(periodFrom);
  const to = moment(periodTo ?? from.add(3, 'days'));
  
  const { terminalStats, terminalTypeStats, statTermStatus, compareTerminalTypeStats, compareTerminalStats} = useTerminalStatistics({ 
    periodType: "Day", 
    from:periodFrom, 
    to:periodTo, 
    profitCenter, 
    profitCenterArea, 
    compareFrom:compareFrom, 
    compareTo:compareTo,
    count:1000 
  });
  
  const { articleStats, articleCompareStats,articleStatStatus, getArticleStatsInGroup} = useArticleStatistics({ 
    periodType: "Day", 
    from: periodFrom, 
    to: periodTo, profitCenter, 
    profitCenterArea, 
    compareFrom: compareFrom, 
    compareTo: compareTo 
  });
  
  const [ rangeStats, rangeCompareStats, rangeStatus ] = useRangeStatistics({
    returnArray: true,
    periodType:'Day',
    from:periodFrom,
    to:periodTo,
    compareFrom:compareFrom,
    compareTo:compareTo,
    profitCenter,
    profitCenterArea
  });

  const statuses = [rangeStatus, statTermStatus, articleStatStatus];
  const loading =  statuses.some(s=>s.status === 'loading' || s.compareStatus === 'loading');

  const round = (value) => {
    return Math.round(value * 100) / 100;
  };

  const  groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  
  const groupedTerminals = groupBy(terminalStats,g=>g.code);
  let summarizedTerminals = [];
  for(let [key, value] of groupedTerminals.entries()) {
    const sum = value.map(s=>s.saleSum).reduce((a, b) => a + b, 0);
    const saleQty = value.map(s=>s.saleQty).reduce((a, b) => a + b, 0);
    const customerQty = value.map(s=>s.customerQty).reduce((a, b) => a + b, 0);
    const item = value[0];
    item.saleSum = sum;
    item.saleQty = saleQty;
    item.customerQty = customerQty;
    summarizedTerminals.push(item);
  }
  summarizedTerminals = summarizedTerminals.sort((a,b)=>b.saleSum-a.saleSum);
  
  const groupedArticles = groupBy(articleStats,g=>g.code);
  const summarizedArticles = [];
  for(let [key, value] of groupedArticles.entries()) {
    const sum = value.map(s=>s.saleSum).reduce((a, b) => a + b, 0);
    const saleQty = value.map(s=>s.saleQty).reduce((a, b) => a + b, 0);
    const item = value[0];
    item.saleSum = sum;
    item.saleQty = saleQty;
    summarizedArticles.push(item);
  }

  summarizedArticles.sort((a,b)=>b.saleSum-a.saleSum);
  
  const customerQty = rangeStats.map(s => s.customerQty).reduce((a, b) => a + b, 0);
  const profitSum = rangeStats.map(s => s.profitSum).reduce((a, b) => a + b, 0);
  const profitPercent = round((rangeStats.map(s => s.profitPercent).reduce((a, b) => a + b, 0)) / rangeStats.length);
  const saleSum = rangeStats.map(s => s.saleSum).reduce((a, b) => a + b, 0);
  const saleTax0Sum = rangeStats.map(s => s.saleTax0Sum).reduce((a, b) => a + b, 0);
  const discounts = rangeStats.map(s => s.discSum).reduce((a, b) => a + b, 0);
  const snittNota = saleSum / customerQty;
  
  const compareMode = (rangeCompareStats?.length ?? 0) > 0;
  
  const compared = !compareMode ? { }:  {
    customerQty: rangeCompareStats?.map(s => s.customerQty).reduce((a, b) => a + b, null),
    profitSum :  rangeCompareStats?.map(s => s.profitSum).reduce((a, b) => a + b, null),
    profitPercent:  round((rangeCompareStats?.map(s => s.profitPercent).reduce((a, b) => a + b, null)) / rangeCompareStats?.length),
    saleSum:  rangeCompareStats?.map(s => s.saleSum).reduce((a, b) => a + b, null),
    saleTax0Sum:  rangeCompareStats?.map(s => s.saleTax0Sum).reduce((a, b) => a + b, null),
    discounts: rangeCompareStats?.map(s => s.discSum).reduce((a, b) => a + b, null)
  };
  
  const getCompareRangeDiff = () =>{
    const a = moment(props.periodFrom).diff(props.periodTo,'days');
    const b = moment(props.compareFrom).diff(props.compareTo,'days');
    
    return a-b;
  }
  
  compared.snittNota = compared.saleSum / compared.customerQty;
  
  return (
    <>
      <div className={"tab-panel-header"}>
        
        <h2 className={"mt-3 text-center"} style={{textTransform: "capitalize"}}>
          {profitCenterArea && !profitCenter && (
              <span>{profitCenterArea.description} - </span>
          )}
          {profitCenter && (
              <span>{profitCenter.name} - </span>
          )}
          <span>{from.format("YYYY-MM-DD")} till {to.format("YYYY-MM-DD")}</span>
          <Loading visible={loading}/>
        </h2>
        {compareMode && (
          <>
            <p className={"m-3 text-center"}>
            {t('sales.range.comparing')} <span>{props.compareFrom} - {props.compareTo}</span>
            </p>
            
            {getCompareRangeDiff() != 0 && (
              <div className="alert alert-warning" role="alert">
                <span>{t('sales.range.diff-warning', {getCompareRangeDiff: getCompareRangeDiff()})}</span>
              </div>
            )}
          </>
        )}
          <div className={"stats-summary"}>
            <StatisticValue className={"m-auto"} text={t('sales.common.net-sales')}         value={saleTax0Sum} compareValue={compared.saleTax0Sum}/>
            <StatisticValue className={"m-auto"} text={t('sales.common.gross-sales')}       value={saleSum}     compareValue={compared.saleSum}/>
            <StatisticValue className={"m-auto"} text={t('sales.common.purchases')}         value={customerQty} formatter={"other"} compareValue={compared.customerQty}/>
            <StatisticValue className={"m-auto"} text={t('sales.common.profit')}            value={profitSum}   compareValue={compared.profitSum}/>
            <StatisticValue className={"m-auto"} text={t('sales.common.profit-percentage')} value={profitPercent} formatter={"percent"} compareValue={compared.profitPercent}/>
            <StatisticValue className={"m-auto"} text={t('sales.common.average-amount')}    value={snittNota}   compareValue={compared.snittNota}/>
            <StatisticValue className={"m-auto"} text={t('sales.common.discounts')}         value={discounts}   compareValue={compared.discounts}/>
          </div>
        </div>
        <div className="tab-panel-content">
          <div className="tab-content-wrapper">
            
            <div className={"chart"}>
              <SalesBarChart keyField={"periodId"} 
                              sales={rangeStats} 
                              compare={rangeCompareStats}
                              title={t('sales.range.sales')}
                              valueKey={'saleSum'}
                              keyFormatter={(key)=>`${moment(key,'YYYYMMDD').format('YYYY-MM-DD')}`}
              />
            </div>
            <div className={"chart"}>
              <SalesBarChart keyField={"periodId"} 
                              sales={rangeStats}
                              compare={rangeCompareStats}
                              title={t('sales.range.num-purchases')}
                              valueKey={'customerQty'} 
                              displayUnits={true}
                              keyFormatter={(key)=>`${moment(key,'YYYYMMDD').format('YYYY-MM-DD')}`}
              />
            </div>
            <div className={"chart"}>
              <SalesBarChart keyField={"periodId"} 
                              sales={rangeStats}
                              compare={rangeCompareStats}
                              title={t('sales.range.profit')}
                              valueKey={'profitSum'}
                              keyFormatter={(key)=>`${moment(key,'YYYYMMDD').format('YYYY-MM-DD')}`}
              />
            </div>
        
          <div className={"group-sales-lists"}>
            <ArticleGroupList title={t('sales.common.article-groups')}
                              sales={summarizedArticles}
                              compareStats={articleCompareStats}
                              expand={getArticleStatsInGroup}
                              loadingData={articleStatStatus.status === 'loading'}
            />
            
            <TerminalSalesList stats={summarizedTerminals}
                                showTerminalList={true}
                                typeStats={terminalTypeStats}
                                compareStats={compareTerminalStats}
                                compareTypeStats={compareTerminalTypeStats}
                                loadingData={statTermStatus.status === 'loading'}
            />
        </div>
      </div>
    </div>
    </>
  )
}

export default RangeDashboard