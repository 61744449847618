import React, {ChangeEvent, useEffect, useState} from "react";
import OpeningHoursRow from "./OpeningHoursRow";
import {OpeningHours, ResponseError} from "../types";
import luxon, {DateTime} from "luxon";
import {enumerateDaysBetweenDates} from "../../firstDayOfPeriod";
import SaveChangesMessage from "../../SaveChangesMessage";
import ButtonConfirm from "../settings/ButtonConfirm";
import {useTranslation} from "react-i18next";

interface Props {
    hours: OpeningHours[],
    mode: "default" | "overrides"
    overrideName?: string
    onSave: (name: string, hours: OpeningHours[]) => undefined | Promise<SaveHoursResult>,
    onCancel: (name: string) => void,
    onDelete?: (overrideName: string) => void
    collapsed?: boolean
}

interface SaveHoursResult {
    success:boolean;
    error?:ResponseError
}

const getDateOnly = (date: string | null): string => {
    if (!date)
        return "";
    const asDateStr = DateTime.fromISO(date).toISODate();
    if (asDateStr == null)
        return "";
    return asDateStr;
}

const ShopOpeningHours = (props: Props) => {
    const [hours, setHours] = useState<OpeningHours[]>(
        props.mode === "overrides" ? props.hours.filter(h => h.isOverride) : props.hours.filter(h => !h.isOverride)
    );

    const collapsible = props.mode === "overrides" && props.collapsed;
    const [validFrom, setValidFrom] = useState<string>(getDateOnly(props.hours[0]?.validFrom));
    const [validTo, setValidTo] = useState<string>(getDateOnly(props.hours[0]?.validThrough));
    const [overrideName, setOverrideName] = useState<string>(props.overrideName ?? "New Override");
    const [collapsed, setCollapsed] = useState<boolean>(props.collapsed ?? false);
    const [saveSuccess, setSavesuccess] = useState<boolean|null>(null);
    const [error, setError] = useState<ResponseError | undefined | null>(null);
    const {t} = useTranslation();
    
    useEffect(() => {
        if (validFrom && validTo && hours.length === 0) {
            const from = DateTime.fromISO(validFrom);
            const to = DateTime.fromISO(validTo);
            if (from > to) {
                setValidTo(validFrom);
            }

            if (Math.abs(from.diff(to, "days").days) > 7) {
                //if the span is greater than seven, we assume the user want to generate a full week
                const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
                const days = weekDays.map((d, idx) => {
                    const day: OpeningHours = {
                        id: -1,
                        dayOfWeek: d,
                        opens: "08:00:00",
                        closes: "23:00:00",
                        closed: false,
                        isOverride: true,
                        overrideName: overrideName,
                        validFrom: validFrom,
                        validThrough: validTo,
                        date: null
                    };
                    return day;
                });
                setHours(days);
            } else {
                const start = from;
                const end = to;
                
                const daysBetweenDates:Date[] = enumerateDaysBetweenDates(validFrom, validTo);
                const daysToEnumerate:Date[] = [];
                
                if(daysBetweenDates.length == 0 && start.equals(end)){
                    daysToEnumerate.push(start.toJSDate())
                } else if (daysBetweenDates.length == 0 && !start.equals(end)){
                    daysToEnumerate.push(start.toJSDate())
                    daysToEnumerate.push(end.toJSDate())
                }
                else {
                    daysToEnumerate.push(start.toJSDate())
                    daysToEnumerate.push(...daysBetweenDates)
                    daysToEnumerate.push(end.toJSDate())
                }
                
                const days = daysToEnumerate
                    .map(d => DateTime.fromJSDate(d))
                    .map(d => {
                        
                        const day: OpeningHours = {
                            id: -1,
                            dayOfWeek: d.weekdayLong ?? "Monday",
                            opens: "08:00:00",
                            closes: "23:00:00",
                            closed: false,
                            overrideName: overrideName,
                            isOverride: true,
                            validFrom: validFrom,
                            validThrough: validTo,

                            date: d.toJSDate()
                        };
                        return day;
                    });
                if (hours.length === 0) {
                    setHours(days);
                }
            }
        }
    }, [validFrom, validTo])

    
    const validToChanged = (e:ChangeEvent<HTMLInputElement>)=>{
        setHours([]);
        setValidTo(e.target.value);
        hours.forEach(h=>h.validThrough = e.target.value)
    }
    const validFromChanged = (e:ChangeEvent<HTMLInputElement>)=>{
        setHours([]);
        setValidFrom(e.target.value);
        hours.forEach(h=>h.validFrom = e.target.value)
        
    }
    const toggleCollapsed = () => {
        if (collapsible)
            setCollapsed(!collapsed);
    }

    const save = () => {
        const prom = props.onSave(overrideName, hours);
        if(prom instanceof Promise) {
            prom.then((res) => {
                if(res && res.success) {
                    setSavesuccess(true);
                    setError(null);
                } else if (res && !res.success) {
                    setSavesuccess(false);
                    setError(res.error);
                }
            });
        }
    }

    const cancel = () => {
        if(props.mode === "overrides")
            props.onCancel(overrideName);
        else {
            props.onCancel("Default");
        }
        
    }
    const deleteByOverrideName = () => {
        props.onDelete && props.onDelete(overrideName);
    };
    
    const onDayChanged = (idx:number, day:OpeningHours)=> {
        if(idx !== -1) {
            const newHours = [...hours];
            newHours[idx] = day;
            setHours(newHours);
        }
    }
    
    const isSpanNow = ():boolean=>{
        return DateTime.fromISO(validFrom) <= DateTime.now() && DateTime.now() <= DateTime.fromISO(validTo);
    }

    return (
        <div className={`shop-opening-hours ${collapsible?"collapsible":""} ${collapsible && collapsed?"collapsed":""}`}>
            <h4 className={"hours-header"} onClick={toggleCollapsed}>
                {collapsible && <span className={`btn-expander ${collapsed?"collapsed":"expanded"}`}></span>} 
                {props.mode === "default" ? t('pick-n-pay.shops.hours.header') : overrideName}

                {props.mode === "overrides" && (
                    <>
                    {validFrom && <span className={"validity"}>
                        {validFrom} - {validTo}
                    </span>}
                    {isSpanNow() && <span className={"active-now"}>{t('pick-n-pay.shops.hours.override.validNow')}</span>}
                    </>
                )}
                
            </h4>
            <div className={"opening-hours-details"}>
                {props.mode === "overrides" && (
                    <div className={"toolbar"}>
                        <div className={"input-group"}>
                            <label className={"form-label input-group-text"}>{t('pick-n-pay.shops.hours.override.name')}</label>
                            <input className={"form-control"} name={"name"} type={"text"} value={overrideName} onChange={(e) => setOverrideName(e.target.value)}/>
                        </div>
                        <div className={"input-group"}>
                            <label className={"form-label input-group-text"}>{t('pick-n-pay.shops.hours.override.validFrom')}</label>
                            <input className={"form-control"} name={"validFrom"} type={"date"} value={validFrom} onChange={validFromChanged}/>
                        </div>
                        <div className={"input-group"}>
                            <label className={"form-label input-group-text"}>{t('pick-n-pay.shops.hours.override.validThru')}</label>
                            <input className={"form-control"} name={"validTo"} type={"date"} value={validTo} onChange={validToChanged}/>
                        </div>

                        <ButtonConfirm title={t('pick-n-pay.shops.hours.override.delete.label')}
                                       className={"btn btn-delete"}
                                       message={t('pick-n-pay.shops.hours.override.delete.message')}
                                       onConfirm={deleteByOverrideName}>
                        </ButtonConfirm>
                    </div>
                )}
                <ol className={"opening-hours"}>
                    {hours.length === 0 && (
                        <li>
                            <p className={"opening-hours-prompt"}>{t('pick-n-pay.shops.hours.override.validText')}</p>
                        </li>
                    )}
                    {hours.map((day, idx) => (
                        <li className={'day'} key={idx}>
                            <OpeningHoursRow idx={idx} mode={props.mode} day={day} onChange={onDayChanged}/>
                        </li>
                    ))}
                </ol>

                <div className={"form-footer"}>
                    <button className={"btn btn-cancel"} onClick={cancel}>{t('pick-n-pay.shops.hours.override.cancel')}</button>
                    <button className={"btn btn-save"} onClick={save}>{t('pick-n-pay.shops.hours.override.save')}</button>
                    <SaveChangesMessage show={saveSuccess === true} onShowDone={()=>setSavesuccess(null)}/>
                    {saveSuccess === false && (
                      <div className="alert alert-danger" role="alert">
                          <div>
                            <h3>{t('pick-n-pay.shops.hours.error.header')}</h3>
                            <p>{error.detail}</p>
                          </div>      
                      </div>
                    )}
                </div>

            </div>
        </div>
    )
}
export default ShopOpeningHours;