import {useState} from "react";
import StatisticValue from "./StatisticValue";
import moment from "moment/moment";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { t } from "i18next";
import Loading from "./Loading";

const TerminalSalesList = (props) => {
  //useTraceUpdate(props, 'TerminalSalesList');
  const {loadingData, periodType, stats, typeStats, compareStats, compareTypeStats, showTerminalList} = props;
  
  const findCompareRow = (code) => {
    return compareStats.find(s => s.code === code);
  };

  const groups = Object.getOwnPropertyNames(typeStats);
 
  if(Object.keys(typeStats).length === 0){
    return (<></>)
  }
  
  const compareMode = compareStats.length > 0;
  const getName = (type) => {
    switch (type) {
      case "kiosk":
        return "Kiosker";
      case "drive":
        return "Drive-In-kassor";
      case "webOrder":
        return "Web";
      case "other":
        return "Övriga kassor";
    }
  };
  
  return (
    <>
        <div className={"terminal-sales-list"}>
          <h2>{t('sales.common.terminal-sales-header')}</h2>
          <Loading visible={loadingData} />
          {stats.length > 0 && (
            <>
          {groups.map((g)=> (
            <StatisticValue key={g} className={"terminal-sales"} formatter={'currency'} text={g}
                            value={typeStats[g].saleSum}
                            compareValue={compareTypeStats[g]?.saleSum}
                            percentage={typeStats[g].percentage}
                            comparePercentage={compareTypeStats[g]?.percentage}
                            quantity={typeStats[g].customerQty}
                            compareQuantity={compareTypeStats[g]?.customerQty}
            />))}
                
          {showTerminalList && (
            <>
              <div className={"mt-3"}>
              <h3>{t('sales.common.pos')}</h3>
              <ul className={"list-group"}>
                {stats.map(row => {
                  return (
                    <TerminalRow key={row.code} item={row} compare={findCompareRow(row.code)} periodType={periodType}/>)
                })}
              </ul>
              </div>
            </>
          )}
            </>
          )}
        </div>
    </>
  )
}

const TerminalRow = (props) => {
  const {item, compare} = props;
  const [showDetails, setShowDetails] = useState(false);
  const formatter = new Intl.NumberFormat("sv-SE", {
    style: 'currency',
    currency: 'SEK'
  });

  const doShowDetails = () => {
    setShowDetails(!showDetails);
  }

  const getClassName = (calcValue) => {
    if (calcValue === 0) {
      return "text-secondary"
    }

    if (calcValue > 0)
      return "compare-value text-success";
    return "compare-value text-danger";
  };

  const getTitleText = () => {
    const displayDate = new Intl.DateTimeFormat('sv-SE', {dateStyle: 'full'}).format(moment(item.periodId, "YYYYMMDD").toDate());
    const compareDisplayDate = new Intl.DateTimeFormat('sv-SE', {dateStyle: 'full'}).format(moment(compare?.periodId, "YYYYMMDD").toDate());

    return `${displayDate} ${item.customerQty} st (${formattedSaleSum}) - ${compareDisplayDate} ${compare?.customerQty} st (${formatter.format(compare?.saleSum)})`;
  }

  const formattedSaleSum = formatter.format(item.saleSum);
  const formattedCompareSaleSum = compare != null ? formatter.format(item.saleSum - compare?.saleSum) : -1;
  
  
  return (
    <li className="list-group-item d-flex justify-content-between align-items-start" onClick={doShowDetails}>
      <div className="ms-2 me-auto ps-2 pe-2">
        <div className="fw-bold">{item.description}
          <span className={"statistic-value"}>({item.customerQty} st)</span>
        </div>
        <div className={"text-end"}>
          {compare && (
            <>
                <span className={getClassName(item.customerQty - compare?.customerQty)} title={getTitleText()}>
                  {item.customerQty - compare.customerQty > 0 && <ChevronUp/>}
                  {item.customerQty - compare.customerQty < 0 && <ChevronDown/>}
                  {item.customerQty - compare.customerQty} st
                </span>
            </>
          )}
        </div>
      </div>
      <div>
        <div className={"statistic-value"}>{formattedSaleSum}</div>
        {compare && (
          <div title={getTitleText()} className={getClassName(item.saleSum - compare.saleSum)}>
            {item.saleSum - compare.saleSum > 0 && <ChevronUp/>}
            {item.saleSum - compare.saleSum < 0 && <ChevronDown/>}
            {formattedCompareSaleSum}
          </div>
        )}
      </div>
    </li>
  )
}

TerminalSalesList.defaultProps = {
  mode: 'default',
  count: 10
};


export default TerminalSalesList;