import React, {useState} from "react";
// @ts-ignore
import {CaretDownFill, CaretRightFill} from "react-bootstrap-icons";
import ShopOpeningHours from "./ShopOpeningHours";
import ShopInfo from "./ShopInfo";
import {OpeningHours, ResponseError, Shop} from "../types";
import {cyrb53} from "../../../cyrb53";
import ButtonConfirm from "../settings/ButtonConfirm";
import luxon, {DateTime, Interval} from "luxon";
import { t } from "i18next";
import {ErrorDetail} from "../../../types";


interface Props {
  shop: Shop
}

interface HoursOverride {
  name: string,
  hours: OpeningHours[],
  collapsed: boolean
}

const groupOverrides = (allOverrides: OpeningHours[]): HoursOverride[] => {
  return allOverrides.reduce((groups: HoursOverride[], current: OpeningHours) => {
    const existing = groups.find(g => g.name === current.overrideName);
    if (existing) {
      existing.hours.push(current);
    } else {
      groups.push({name: current.overrideName ?? "ERROR", hours: [current], collapsed: true});
    }
    return groups;
  }, []);
}

export const ShopDetails = (props: Props) => {
  const {shop} = props;
  const [overrides, setOverrides] = useState<HoursOverride[]>(groupOverrides(shop.openingHours.filter(s => s.isOverride)));
  const [defaultHours, setDefaultHours] = useState<OpeningHours[]>(shop.openingHours.filter(h => !h.isOverride));
  
  const createOverride = () => {
    const name = getUniqueName(t("pick-n-pay.shops.hours.override.newOverrideDefaultName"), overrides.map(o => o.name));
    setOverrides([...overrides, {name: name, hours: [], collapsed: false}]);
  };

  const getUniqueName = (name: string, otherNames: string[]): string => {
    if (otherNames.indexOf(name) === -1)
      return name;

    let i = 1;
    while (otherNames.indexOf(`${name} ${i}`) !== -1) {
      i++;
    }
    return `${name} ${i}`;
  }

  const validateHourOverrides = (allOverrides:OpeningHours[])=>{
    if(!allOverrides.every(h=>h.validFrom < h.validThrough)){
      return false;
    }
    let failed = false;
    allOverrides.forEach(h=>{
      if(failed) 
        return;
      const other = allOverrides.filter(o=> o.overrideName != h.overrideName);
      const validFrom = DateTime.fromISO(h.validFrom);
      const validThrough = DateTime.fromISO(h.validThrough);
      
      const range = Interval.fromDateTimes(validFrom, validThrough);
      other.forEach(o=>{
        if(failed) 
          return;
        
        const otherValidFrom = DateTime.fromISO(o.validFrom);
        const otherValidThrough = DateTime.fromISO(o.validThrough);
        const otherRange = Interval.fromDateTimes(otherValidFrom, otherValidThrough);
        
        if(range.overlaps(otherRange)){
          console.log('overlap:', h.overrideName,`${validFrom.toISODate()} - ${validThrough.toISODate()}`,"overlaps with" , o.overrideName, `${otherValidFrom.toISODate()}-${otherValidThrough.toISODate()}`)
          failed = true;
        }
      });
    });
    
    return !failed;
  }
  const onCancelDefaultHoursChanges = ()=>{
      
  }

  const onHoursChanged = (name: string, hours: OpeningHours[]) => {
    const overrideName = hours[0].overrideName;
    if(overrideName != "Default"){
      const otherOverrides = overrides
        .filter(f=>f.name !== overrideName)
        .map(m=>m.hours)
        .reduce((acc, val)=>acc.concat(val), [])
      
      const allOverrides = otherOverrides.concat(hours);
      
      if(!validateHourOverrides(allOverrides)) {
        alert("Invalid override hours");
        return;
      }
    }
    
    hours.forEach(h => {
      const reqLength = "23:00:00".length
      if (h.opens.length < reqLength)
        h.opens += ":00";
      if (h.closes.length < reqLength)
        h.closes += ":00";
    });

    return fetch(`api/pick-n-pay/shops/${shop.id}/hours`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(hours)
    }).then(response=>{
      if(response.ok)
        return response.json();
      return response.json().then((data:ResponseError[])=>[response, data]);
    }).then(data => {
      if(data instanceof Array) {
        const response = data[0] as Response;
        const err = data[1] as ResponseError;
        if(response.ok === false){
          return {
            success: false,
            error: err
          };
        }
      }
      return { success: true}
    });
  }
  

  const onOverrideCanceled = (name: string) => {
      console.log(name);
    const override = overrides.find(o => o.name === name);
    if (override) {
      console.log(override);
      override.collapsed = true;
      setOverrides([...overrides]);
    }
  };
  const onOverrideDelete = (name: string) => {

    fetch(`api/pick-n-pay/shops/${shop.id}/hours`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({overrideName: name})
    })
      .then(r => {
        if (r.ok) {
          setOverrides(overrides.filter(o => o.name !== name));
        }
      });
  }

  const deleteShop = () => {
    fetch(`api/pick-n-pay/shops/${shop.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(r => {
        //TODO response validation
        window.location.reload();
      });
  }

  return (
    <div className={`result-container shop status-${shop.status} shop-${shop.openForBusiness ? 'open' : 'closed'}`}>
      <h3 className={"shop-header"}>
        {shop.description}
        <span className={"shop-id"}>- {shop.id}</span>

        <ButtonConfirm title={"Delete shop"} className={"btn btn-delete"} message={t('pick-n-pay.shops.deleteShop.confirmText')} onConfirm={deleteShop}>
          {t('pick-n-pay.shops.deleteShop.deleteShop')}
        </ButtonConfirm>
      </h3>

      <div className={"shop-details"}>
        <ShopOpeningHours mode={"default"} hours={defaultHours} onSave={onHoursChanged} onCancel={onCancelDefaultHoursChanges}/>
        <ShopInfo shop={shop}/>
      </div>

      <h4>{t('pick-n-pay.shops.hours.override.header')}</h4>
      <ol className={"overrides"}>
        {overrides.map((o, idx) => (
          <li key={cyrb53(o.name)} className={"opening-hours-override"}>
            <ShopOpeningHours mode={"overrides"} hours={o.hours} collapsed={o.collapsed} overrideName={o.name} 
                              onSave={onHoursChanged} 
                              onCancel={onOverrideCanceled}
                              onDelete={onOverrideDelete}
            />
          </li>
        ))}
      </ol>

      <button className={"btn btn-large-add"} onClick={createOverride}>{t('pick-n-pay.shops.hours.override.addOverride')}</button>
    </div>
  )
}
export default ShopDetails;

